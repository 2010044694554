import React from "react";

import { Box } from "@mui/material";

import { useSettings } from "../SettingsProvider";

const HamburgerButton = ({ open, style, component, onClick }) => {
  const { theme, darkHeader } = useSettings();

  const burgerLineSx = {
    display: "block",
    position: "absolute",
    height: 2,
    width: 20,
    background: darkHeader && !open ? "white" : theme.palette.primary.dark,
    borderRadius: "2px",
    left: 3,
    transition: ".25s ease-in-out",
  };

  return (
    <Box
      component={component}
      style={{
        ...style,
        position: "relative",
        width: 26,
        height: 26,
        cursor: "pointer",
        zIndex: theme.zIndex.modal,
      }}
      onClick={onClick}>
      <span
        style={{
          ...burgerLineSx,
          top: open ? 12 : 6,
          transform: open ? "rotate(135deg)" : "rotate(0deg)",
        }}></span>
      <span
        style={{
          ...burgerLineSx,
          top: 12,
          left: open ? -20 : 3,
          opacity: open ? 0 : 1,
        }}></span>
      <span
        style={{
          ...burgerLineSx,
          top: open ? 12 : 18,
          transform: open ? "rotate(-135deg)" : "rotate(0deg)",
        }}></span>
    </Box>
  );
};

export default HamburgerButton;
