import React from "react";

import { Link as GatsbyLink } from "gatsby";

import { Box, Divider, Link, Slide, Stack, Typography } from "@mui/material";

import { useSettings } from "../SettingsProvider";
import Logo from "../brand/Logo";
import LetsTalk from "../common/LetsTalk";
import { HeaderNavLinks } from "./NavLinks";

const SideMenu = ({ open, setOpen, logoSx }) => {
  const { theme } = useSettings();

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: open ? "flex" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}>
        <Box
          sx={{
            display: { xs: "none", md: open ? "flex" : "none" },
            width: "100vw",
            height: "100vh",
            backgroundColor: theme.palette.grey[900],
            opacity: 0.75,
          }}>
          {/* overlay */}
        </Box>
        <Box
          sx={{
            display: open ? "flex" : "none",
            flexDirection: "column",
            position: "absolute",
            top: 0,
            right: 0,
            width: { xs: "100vw", md: "25vw" },
            height: "100vh",
            backgroundSize: "256px",
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[5],
          }}>
          <Box display="flex" alignItems="center" p={1} pr={{ xs: 0, md: 1 }}>
            <Logo sx={{ ...logoSx, display: "flex", mr: "auto" }} />
          </Box>
          <Divider />
          {HeaderNavLinks.map((item, index) => {
            if (item.href) {
              return (
                <React.Fragment key={item.name}>
                  <Box component="nav" key={item.name} sx={{ p: 2 }}>
                    <Link
                      component={GatsbyLink}
                      color="primary.main"
                      underline="none"
                      sx={{ mr: 4 }}
                      to={item.href}
                      onClick={() => setOpen(false)}>
                      {item.name}
                    </Link>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={item.name}>
                  <Box
                    component="nav"
                    sx={{
                      p: 2,
                    }}>
                    <Typography color="primary.main" sx={{}}>
                      {item.name}
                    </Typography>
                  </Box>
                  <Divider />
                  <Stack onClick={() => setOpen(false)}>
                    {item.menu.map((menuItem) => (
                      <React.Fragment key={menuItem.name}>
                        <Link
                          component={GatsbyLink}
                          color="textPrimary"
                          underline="none"
                          sx={{
                            p: 2,
                            pl: 6,
                          }}
                          to={menuItem.href}>
                          {menuItem.name}
                        </Link>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </Stack>
                </React.Fragment>
              );
            }
          })}
          <LetsTalk sx={{ ml: "auto", mt: "auto" }} onClick={() => setOpen(false)} />
        </Box>
      </Box>
    </Slide>
  );
};

export default SideMenu;
