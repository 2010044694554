import { useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";

const SettingsContext = React.createContext();

export const SettingsProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const heroHeight = isMobile ? "80vh" : "70vh";
  const minHeroHeight = 500;
  const pageWidth = theme.breakpoints.values["xl"];
  //true: light text on dark background
  //false: dark text on light background
  const [darkHeader, setDarkHeader] = useState(false);

  return <SettingsContext.Provider value={{ theme, isMobile, heroHeight, minHeroHeight, pageWidth, darkHeader, setDarkHeader }}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);
