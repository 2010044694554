exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-cloud-native-jsx": () => import("./../../../src/pages/services/cloud-native.jsx" /* webpackChunkName: "component---src-pages-services-cloud-native-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-solutions-ai-jsx": () => import("./../../../src/pages/solutions/ai.jsx" /* webpackChunkName: "component---src-pages-solutions-ai-jsx" */),
  "component---src-pages-solutions-startups-jsx": () => import("./../../../src/pages/solutions/startups.jsx" /* webpackChunkName: "component---src-pages-solutions-startups-jsx" */)
}

