import React from "react";

import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";

import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Social from "./Social";
import { FooterNavLinks } from "./NavLinks";
import Logo from "../brand/Logo";

let halfway = Math.ceil(FooterNavLinks.length / 2);

const LeftFooterNavLinks = FooterNavLinks.slice(0, halfway);

const RightFooterNavLinks = FooterNavLinks.slice(halfway);

const Footer = () => {
  const theme = useTheme();
  const textColor = theme.palette.grey[600];

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Box
      sx={{
        zIndex: 100,
        position: "relative",
        padding: 4,
        flexGrow: 1,
        mt: { xs: 8, md: 4 },
      }}>
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
            mb: { xs: 2, md: 0 },
          }}>
          <Logo sx={{ width: { xs: "96px", md: "128px" }, height: { xs: "96px", md: "128px" } }} />
          <Typography
            typography={{ xs: "h3", md: "h4", lg: "h3" }}
            letterSpacing={{ xs: 1, md: 4 }}
            fontWeight={{ xs: 200, md: 200, lg: 100 }}
            ml={{ xs: 0, md: 2 }}
            color={theme.palette.logo.main}>
            medhavyn
          </Typography>
        </Grid>
        <Grid xs={6} md={2} sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "center" } }}>
          <Stack>
            {LeftFooterNavLinks.map((item, index) => {
              return (
                <Link
                  component={GatsbyLink}
                  color={textColor}
                  underline="none"
                  sx={{ mt: index > 0 ? 2 : 0 }}
                  to={item.href}
                  key={item.name}>
                  {item.name}
                </Link>
              );
            })}
          </Stack>
        </Grid>
        <Grid xs={6} md={2} sx={{ display: "flex", justifyContent: { xs: "flex-end", md: "center" } }}>
          <Stack sx={{ display: "flex", alignItems: { xs: "flex-end", md: "flex-start" } }}>
            {RightFooterNavLinks.map((item, index) => {
              return (
                <Link
                  component={GatsbyLink}
                  color={textColor}
                  underline="none"
                  sx={{ mt: index > 0 ? 2 : 0 }}
                  to={item.href}
                  key={item.name}>
                  {item.name}
                </Link>
              );
            })}
          </Stack>
        </Grid>
        <Grid
          xs={12}
          md={4}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-end" }}
          sx={{ mt: { xs: 4, md: 0 } }}>
          <Social direction={"row"} color={textColor} />
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            p: 1,
            mt: 4,
          }}>
          <Typography variant="body2" color={textColor}>
            © Copyright {new Date().getFullYear()} {data.site.siteMetadata.title} Pvt. Ltd.
          </Typography>
          <Typography variant="overline" color={theme.palette.grey[400]}>
            All logos are properties of respective owners. Graphics from freepik.com
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
