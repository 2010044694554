import React, { useState } from "react";

import { Link as GatsbyLink } from "gatsby";

import { Box, Button } from "@mui/material";
import { motion, useMotionValueEvent, useScroll, useSpring, useTransform } from "framer-motion";

import { useSettings } from "../SettingsProvider";
import Logo from "../brand/Logo";
import ClickToNavMenu from "./ClickToNavMenu";
import HamburgerButton from "./HamburgerButton";
import { HeaderNavLinks } from "./NavLinks";
import SideMenu from "./SideMenu";

const Header = () => {
  const { theme, isMobile, darkHeader } = useSettings();
  const [showMenu, setShowMenu] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const { scrollYProgress } = useScroll();
  const scrollThreshold = 0.2;
  const navOpacity = useTransform(scrollYProgress, [0, 0.1], [1, 0]);
  const navMenuOpacity = useSpring(navOpacity, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const hMenuOpacity = useSpring(useTransform(scrollYProgress, [0, 0.1], [0, 1]), {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const navButtonStyle = {
    "marginRight": 4,
    "fontSize": 18,
    "whiteSpace": "nowrap",
    "&.MuiButtonBaseRoot:hover": {
      bgcolor: "transparent",
    },
  };

  const logoSx = {
    width: { xs: "40px", md: "48px" },
    height: { xs: "40px", md: "48px" },
  };

  useMotionValueEvent(scrollYProgress, "change", (y) => {
    setHideNav(y > scrollThreshold);
    setShowMenu(false);
  });

  return (
    <Box
      display="flex"
      width="100%"
      minHeight={{ xs: "52px", md: "60px" }}
      position="fixed"
      zIndex={theme.zIndex.appBar}>
      {/* use style here instead of sx for animating opacity on scroll */}
      {/* Logo button */}
      {/* Extra Box(div) neeeded around Logo to animate opacity */}
      <Box
        component={motion.div}
        style={{
          marginLeft: 0,
          marginRight: "auto",
          opacity: navMenuOpacity,
          display: hideNav ? "none" : "flex",
        }}>
        <Button
          component={GatsbyLink}
          to="/"
          variant="text"
          sx={{
            ...navButtonStyle,
          }}>
          <Logo mono={darkHeader ? "white" : ""} sx={{ ...logoSx }} />
        </Button>
      </Box>
      {/* Desktop Menu */}
      <Box
        component={motion.nav}
        style={{
          opacity: navMenuOpacity,
          display: hideNav || isMobile ? "none" : "flex",
          marginRight: "auto",
        }}>
        {HeaderNavLinks.map((item) => {
          if (item.href) {
            return (
              <Button
                component={GatsbyLink}
                to={item.href}
                sx={{ ...navButtonStyle, fontWeight: 400 }}
                key={item.name}
                variant="text"
                color={darkHeader ? "LinkOnDark" : "LinkOnLight"}>
                {item.name}
              </Button>
            );
          } else {
            return <ClickToNavMenu key={item.name} navLink={item} />;
          }
        })}
      </Box>
      <HamburgerButton
        open={showMenu}
        component={motion.div}
        style={{
          opacity: isMobile ? 1 : hMenuOpacity,
          display: hideNav || isMobile ? "flex" : "none",
          marginTop: "auto",
          marginBottom: "auto",
          marginLeft: "auto",
          marginRight: theme.spacing(1),
          zIndex: theme.zIndex.modal,
        }}
        onClick={() => setShowMenu((prevVal) => !prevVal)}
      />
      <SideMenu open={showMenu} setOpen={setShowMenu} logoSx={logoSx} />
    </Box>
  );
};
export default Header;
