import React from "react";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import PlaceIcon from "@mui/icons-material/Place";
import { Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Unstable_Grid2/";

export const SocialItems = [
  { icon: LinkedInIcon, url: "https://www.linkedin.com/company/medhavyn-technologies/", name: "Medhavyn Technologies" },
  { icon: TwitterIcon, url: "https://twitter.com/medhavyn", name: "@medhavyn" },
  { icon: PlaceIcon, url: "https://goo.gl/maps/y5WGiFWNCv6B8spbA", name: "Nashik, India" },
];

export default function Social({ direction, color }) {
  return (
    <Grid container direction={direction || "row"} spacing={1}>
      {SocialItems.map((item) => (
        <Grid key={item.name}>
          <Link href={item.url}>
            <IconButton>
              <item.icon sx={{ color: color }} />
            </IconButton>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
