import React from "react";

import { Box, CssBaseline } from "@mui/material";

import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ disablePadding = true, children }) => {
  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ p: disablePadding ? 0 : { xs: 2, md: 4 }, overflow: "hidden" }}>{children}</Box>
      <Footer />
    </>
  );
};
export default Layout;
