import React from "react";

import { Link as GatsbyLink } from "gatsby";

import { Button } from "@mui/material";

const LetsTalk = ({ sx, variant, onClick }) => {
  return (
    <Button
      component={GatsbyLink}
      to="/contact-us"
      variant={variant ? variant : "contained"}
      onClick={onClick}
      color="primary"
      size="large"
      sx={{ mr: 2, mb: 2, ...sx }}
    >
      Let's talk
    </Button>
  );
};

export default LetsTalk;
