import React from "react";

import { Link as GatsbyLink } from "gatsby";

import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSettings } from "../SettingsProvider";

const navButtonStyle = {
  mr: 4,
  fontSize: 18,
  whiteSpace: "nowrap",
};

const ClickToNavMenu = ({ navLink }) => {
  const { darkHeader } = useSettings();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (_event) => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <Button
        sx={{ ...navButtonStyle, fontWeight: 400 }}
        ref={anchorRef}
        variant="text"
        focusRipple={false}
        onClick={handleToggle}
        color={darkHeader ? "LinkOnDark" : "LinkOnLight"}
        endIcon={<ExpandMoreIcon />}>
        {navLink.name}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  {navLink.menu.map((it) => (
                    <MenuItem key={it.name} component={GatsbyLink} to={it.href} onClick={handleClose}>
                      {it.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
export default ClickToNavMenu;
