import React from "react";

import { ThemeProvider } from "@mui/material";

import { SettingsProvider } from "./src/components/SettingsProvider";
import Layout from "./src/components/layout/Layout";
import { blueGreen } from "./src/themes/blueGreen";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={blueGreen}>
      <SettingsProvider>{element}</SettingsProvider>
    </ThemeProvider>
  );
};

//Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm("This application has been updated. Reload to display the latest version?");

  if (answer === true) {
    window.location.reload();
  }
};
