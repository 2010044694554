import { createTheme } from "@mui/material";
import { grey, orange } from "@mui/material/colors";

export const blueGreen = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#333A70",
    },
    secondary: {
      main: "#929BB5",
    },
    background: {
      default: "#fffbff",
    },
    text: {
      primary: "#171321",
    },
    logo: {
      main: "#0057B3",
      accent: orange[700],
    },
    shadow: {
      main: grey[700],
      dark: grey[900],
      light: grey[500],
    },
    keyOfferings: {
      boxDark: "#929BB5",
      boxLight: "#eeeeee",
      contrastText: "#FFF",
    },
    cloudNative: {
      main: "#0057B3",
      light: "#3378C2",
      dark: "#003C7D",
      contrastText: "#FFF",
    },
    cloudComputing: {
      main: "#00375e",
      light: "#335F7E",
      dark: "#002641",
      contrastText: "#FFF",
    },
    apps: {
      main: "#51ad31",
      light: "#73BD5A",
      dark: "#387922",
      contrastText: "#fff",
    },
    devops: {
      main: "#f46a36",
      light: "#F6875E",
      dark: "#AA4A25",
      contrastText: "#FFF",
    },
    startups: {
      main: "#929BB5",
      light: "#a7afc3",
      dark: "#666c7e",
      contrastText: "#FFF",
    },
    ai: {
      main: "#1b263b",
      contrastText: "#FFF",
    },
    LinkOnDark: {
      main: "#FFFFFF",
    },
    LinkOnLight: {
      main: "#333A70",
    },
  },
  typography: {
    fontFamily: "Inter, Roboto, sans-serif",
    h1: {
      fontSize: "4rem",
    },
    h6: {
      fontWeight: 400,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
