export const HeaderNavLinks = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Why Medhavyn?",
    href: "/about",
  },
  {
    name: "For Startups",
    href: "/solutions/startups",
  },
  {
    name: "Applied AI",
    href: "/solutions/ai",
  },
  {
    name: "Services",
    menu: [
      { name: "Cloud Native", href: "/services/cloud-native" },
      { name: "Cloud Computing", href: "/services#cloud-computing" },
      { name: "Mobile & Web Apps", href: "/services#apps" },
      { name: "DevOps", href: "/services#devops" },
    ],
  },
  {
    name: "Contact Us",
    href: "/contact-us",
  },
];

export const FooterNavLinks = [
  { name: "For Startups", href: "/solutions/startups" },
  { name: "Cloud Native", href: "/services/cloud-native" },
  { name: "About Us", href: "/about" },
  { name: "Applied AI", href: "/solutions/ai" },
  { name: "Services", href: "/services" },
  { name: "Contact Us", href: "/contact-us" },
];
