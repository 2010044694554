import React from "react";

import { SvgIcon, useTheme } from "@mui/material";

const Logo = ({ mono, sx, ...rest }) => {
  const theme = useTheme();
  const bodyColor = mono ? mono || "white" : theme.palette.logo.main;
  const dotColor = mono ? mono || "white" : theme.palette.logo.main;
  const quadrantColor = mono ? mono || "white" : theme.palette.logo.accent;
  const shadow = mono
    ? {}
    : {
      filter: `drop-shadow(1px 1px 2px ${theme.palette.shadow.main})`,
    };

  return (
    <SvgIcon viewBox="0 0 512 512" sx={{ ...sx, ...shadow }} {...rest}>
      <path d="m184 292a36 36 0 0 0-36 36 36 36 0 0 0 36 36 36 36 0 0 0 36-36 36 36 0 0 0-36-36z" fill={dotColor} />
      <path
        d="m509.43 292h-217.43v28h-0.33008a108 108 0 0 1 0.33008 8 108 108 0 0 1-108 108 108 108 0 0 1-108-108 108 108 0 0 1 108-108 108 108 0 0 1 8 0.33008v-0.33008h28v-217.43a256 256 0 0 0-220 253.43 256 256 0 0 0 256 256 256 256 0 0 0 253.43-220z"
        fill={bodyColor}
      />
      <path d="m292 2.5781v217.42h217.42a256 256 0 0 0-217.42-217.42z" fill={quadrantColor} />
    </SvgIcon>
  );
};
export default Logo;
